import React from "react";

function About() {
  return (
    <>
      <span>About Page</span>
    </>
  );
}

export default About;
