import React from "react";
import "./Projects.scss";

function Projects() {
  return (
    <>
      <div className="welcome-container">
        <div className="welcome-body">This is where projects go</div>
      </div>
    </>
  );
}

export default Projects;
